<template>
  <div class="top-png" :id="`topPng${imgModule}`">
    <canvas style="display: none" :id="`canvas${imgModule}`" width="1920"></canvas>
  </div>
</template>

<script>
import indexApi from "@/api/index.js";
import { mapState } from "vuex";
export default {
  props: {
    imgModule: {
      type: String,
      default: '7'
    }
  },
  data() {
    return {
      list: [],
      imgUrl: '',
      AD: {}
    }
  },
  methods: {
    async jump() {
      if (this.AD.url) {
        await indexApi.imageClick({id: this.AD.id})
        window.open(this.AD.url)
      }
    },
    async getBannerList(){
      let { code, data, message } = await indexApi.findBanner({
        module: this.imgModule // 1: '顶部轮播图',2: '公告轮播图',6: '内页banner',7: '顶部广告位',8: '底部广告位',9: '内页广告位',
      });
      if (code === 200) {
        this.list = data;
        this.imgUrl = this.list[0].attachFilePath
        this.AD = data[0]
      }
    }
  },
  async mounted() {
    await this.getBannerList()
    this.$nextTick(() => {
      let img = new Image()
      let canvas = document.getElementById(`canvas${this.imgModule}`)
      let box = document.getElementById(`topPng${this.imgModule}`)
      let ctx = canvas.getContext('2d')
      img.src = this.baseURL + this.imgUrl
      img.onload = function () {
        // img.style.height = '108px'
        img.style.width = '1920px'
        ctx.drawImage(img, 0, 0, 1920, 108)
        img.id = 'img'
        const leftImageData = ctx.getImageData(0, 0, 1, 1);
        const leftPixels = leftImageData.data; // 包含像素颜色信息的一维数组
        const rightImageData = ctx.getImageData(canvas.width-1, 0, 1 , 1);
        const rightPixels = rightImageData.data; // 包含像素颜色信息的一维数组
        box.style.background = `linear-gradient(to right, rgb(${leftPixels[0]}, ${leftPixels[1]}, ${leftPixels[2]}) 0%, rgb(${leftPixels[0]}, ${leftPixels[1]}, ${leftPixels[2]}) 50%, rgb(${rightPixels[0]}, ${rightPixels[1]}, ${rightPixels[2]}) 60%, rgb(${rightPixels[0]}, ${rightPixels[1]}, ${rightPixels[2]}) 100%)`
      }
      img.crossOrigin = "anonymous"
      img.onclick = () => {
        this.jump()
      }
      box.appendChild(img)
    })
  },
  computed: {
    ...mapState(["baseURL"])
  }
}
</script>

<style lang="less" scoped>
  .top-png {
    // height: 108px;
    // background:  linear-gradient(to right, #373348 0%, #373348 50%, #090750 60%, #090750 100%);;
    display: flex;
    justify-content: center;
    overflow: hidden;
    img {
      // height: 108px;
    }
  }
</style>